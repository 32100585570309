@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Montserrat', sans-serif;
}

/* ////////////////////////////////////////// section report ///////////////////////////////////// */

.section_report {
    padding: 30px 0 0;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: block;
    unicode-bidi: -webkit-isolate;
    unicode-bidi: isolate;
    font-family: GT Pressura,sans-serif;
    color: #000000;

    @media (max-width: 800px) {
        padding: 10px 0 0;
    }
}

.container_grid {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
  }

.equal_px_grid {
    padding-left: 20px!important;
    padding-right: 20px!important;

    @media (max-width: 800px) {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
}

.section_report_uptitle {
    font-family: GT Pressura,sans-serif;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0 0 5px;
}

.section_report_downtitle {
    font-family: GT Pressura,sans-serif;
    font-size: 72px;
    line-height: 1.1;
    font-weight: bold;
    margin: 0;
    /* margin: 0 0 30px; */

    @media (max-width: 800px) {
        font-size: 48px;
    }
}

.popular_comparisons {
    padding: 20px 0;
    box-sizing: border-box;
}

.comparison_grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    gap: 20px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        overflow: auto;
        scrollbar-width: none;
    }
}

.comparison_card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    width: 240px;
    height: 320px;

    @media (max-width: 800px) {
        width: 180px;
    }

}

.comparison_card_a {
    color: #000000;
    text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    outline: none;
}

.comparison_image {
    position: relative;
    aspect-ratio: 1;
    box-sizing: border-box;
    width: 240px;
    height: 320px;

    @media (max-width: 800px) {
        width: 180px;
    }

}

.comparison_image img {
    object-fit: contain;
    width: 100%;
    height: 100%;

    @media (max-width: 800px) {
        object-fit: cover;

    }
}

.comparison_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* bottom: 0; */
    padding: 20px;
    box-sizing: border-box;
}

.comparison_text {
    color: white;
    box-sizing: border-box;
    /* background-color: #0e0e0e78; */

}

.best_label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    box-sizing: border-box;
    font-family: GT Pressura,sans-serif;
}

.comparison_text h3 {
    font-size: 18px;
    font-family: GT Pressura,sans-serif;
    margin: 0;
    font-weight: bold;
    line-height: 1.2;
}

.container_carousel_div {
    box-sizing: border-box;
}

.carousel_wrapper_div {
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

/* ////////////////////////////////////////// section store ///////////////////////////////////// */


.section_store {
    box-sizing: border-box;
}

.store_container {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
}

.store_flex_container {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        grid-gap: 25px;
        gap: 25px;
    }
}

.store_header {
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    width: -webkit-fill-available;
    justify-content: space-between;
    box-sizing: border-box;
}

.store_titles_section {
    box-sizing: border-box;
}

.store_titles_section h2 {
    color: #000;
    text-wrap: nowrap;
    font-family: GT Pressura,sans-serif;
    font-size: 50px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -2.5px;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 40px;

    }
}

.store_subtitle {
    color: #716e76;
    font-family: GT Pressura,sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
    letter-spacing: -0.16px;
    margin: 0px;
    font-weight: 500;
    box-sizing: border-box;
}

.store_wrapper_carousel {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.store_wrapper_carousel ul {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    overflow: hidden;
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        overflow: auto;
        scrollbar-width: none;
    }
}

.store_wrapper_carousel ul li {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
    width: 420px;
    height: 480px;
    border-radius: 32px;
    background-color: #f1efe959;
    -webkit-backdrop-filter: blur(62px);
            backdrop-filter: blur(62px);
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 300px;
        height: auto;
        align-items: center;
    }
    
}

.store_wrapper_carousel ul li .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1 / 0.8;
    overflow: hidden;
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .cover img {
    height: 100%;
    width: auto;
    object-fit: contain;
    border-radius: 26px;
    vertical-align: middle;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 270px;
    }
}

.store_wrapper_carousel ul li .info {
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .info p {
    color: #716e76;
    font-family: GT Pressura,sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.18px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .info span {
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .info a {
    color: #000000;
    text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    outline: none;

    box-sizing: border-box;

}
.store_wrapper_carousel ul li .info a.store_children_categories {
    display: flex;
    color: #716e76;
    font-size: 15px;
    position: relative;
    justify-content: center;
    box-sizing: border-box;
}

/* ////////////////////////////////////////// section buyer ///////////////////////////////////// */

.section_buyer {
    padding: 50px 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 10px 0;

    }
}

.buyer_container {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
}

.buyer_reverse {
    box-sizing: border-box;

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.buyer_reverse_text_container {
    box-sizing: border-box;
}

.buyer_reverse_text_container h2 {
    font-family: GT Pressura,sans-serif;
    font-size: 38px;
    line-height: 1.1;
    font-weight: 500;
    margin: 0 0 10px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 31px;
    }
}

.buyer_reverse_text_container p {
    font-family: GT Pressura,sans-serif;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    margin: 0 0 10px;
    color: #716E76;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 17px;
    }
}

.section_buyer img {
    width: 100%;
    height: auto;
    border-radius: 32px;
    vertical-align: middle;
    box-sizing: border-box;
}

.buyer_m_t_50 {
    margin-top: 50px;

    @media (max-width: 800px) {
        margin-top: 20px;
    }
}

.buyer_m_t_50 h2 {
    font-family: GT Pressura,sans-serif;
    font-size: 38px;
    line-height: 1.1;
    font-weight: 500;
    margin: 0 0 10px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 31px;
    }
}

.buyer_m_t_50 p {
    font-family: GT Pressura,sans-serif;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    margin: 0 0 10px;
    color: #716E76;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 17px;
    }
}

/* ////////////////////////////////////////// section subscribe ///////////////////////////////////// */
.section_subscribe {
    padding: 50px 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 0px 30px 0px;
    }
}

.subscribe_container {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
}

.subscribe_container h2 {
    color: #000;
    font-family: GT Pressura,sans-serif;
    font-size: 38px;
    line-height: 1.1;
    font-weight: 500;
    margin: 0 0 32px;
    text-align: center !important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 30px;
    }
}

.carousel-container {
    overflow: hidden;
    position: relative;
    height: 100px;
    border-color: #4b5563;
    width: 100%;
  }
  
  .carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 38px;
    /* &:hover {
      transform: scale(0.9);
      box-shadow: 5px 5px 8px rgba(222, 222, 222, 0.362),
        10px 10px 8px rgba(152, 133, 133, 0.392),
        15px 15px 8px rgba(139, 154, 139, 0.303);
    } */
  }
  
  .carousel-card > img {
    height: 80%;
    border-radius: 10%;
  }
  
  .carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100px;
    grid-gap: 16px;
    gap: 16px;
    width: 200%;
  }

  .carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 80px;
    grid-gap: 90px;
    gap: 90px;
    width: 200%;
    height: 100%;
    -webkit-animation: slide 20s linear infinite;
            animation: slide 20s linear infinite;
    /* &:hover {
        animation-play-state: paused;
    } */
  }

  @-webkit-keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  @keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
#root {
    /* height: 100%;
    overflow-x: hidden;
    margin: 0; */
    background-color: #fff;
}
body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root1 {
    background-color: #fff;
    @media (max-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    
}
.total_container {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div {
    flex: 1 0 auto;
}
.foot_img_div {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img {
    height: 149px;
    width: 100%;
    /* background-image: url("assets/houses2.webp"); */
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div {
  padding: 25px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result {
  padding: 15px 0px;
  /* border-bottom: 1px solid rgb(230,230,230); */
}
.content_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div {
    width: 115px;
}
.content_quiz_div {
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.result_div_font {
    font-family: "Roboto";
}
.quiz_div_fadeOut {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn {
  opacity: 1;
  transition: opacity 1.5s;
  
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
.content_quiz_container {
    margin-top: 60px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 15px;
}
.quiz_question_div {
    font-size: 40px!important;
    line-height: 44px!important;
    margin-bottom: 50px;
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container {
    display: flex;
    justify-content: center;
}
.quiz_answers_button_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    min-height: 190px;
    transition: -webkit-transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s, -webkit-transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
}
.quiz_answers_button_div:hover {
    -webkit-transform: translate(0px, -5%);
            transform: translate(0px, -5%);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span {
    background-image: url(/static/media/yes.50e2ec53.svg);
    background-size: 70%;
    margin: 20px auto 0px;
    display: block;
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span {
    /* height: 55px; */
    margin: 5px;
    line-height: 22px;
    /* font-size: 17px; */
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    text-align: center;
    display: block;
}

.quiz-container1 {
    @media (min-width: 800px) {
      width: 800px;
      min-width: 250px;
      background: #ffffff;
      border-radius: 4px;
      margin: 50px auto;
      padding: 100px 60px;
      display: flex;
      flex-direction: column;
      background: transparent;
    }
  
    background: #ffffff;
    border-radius: 4px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  .quiz-container1 h2 {
    /* text-align: center; */
    @media (min-width: 800px) {
      font-size: 1.8rem;
      color: #161a1f;
      line-height: 1.4;
      font-weight: 500;
      margin: 0 0 0 0;
    }
  
    font-size: 1.5rem;
    color: #161a1f;
    line-height: 1.3;
    font-weight: 500;
    margin: 0 0 0 0;
  }
  
  .quiz-container1 h4 {
    @media (min-width: 800px) {
      font-size: 1.6rem;
      color: #161a1f9c;
      line-height: 1.4;
      font-weight: 500;
      margin: 10px 0 0 0;
    }
    font-size: 1.3rem;
    color: #161a1f9c;
    line-height: 1.2;
    font-weight: 500;
    margin: 10px 0 0 0;
  }

  .quiz-container1 ul {
    margin-top: 20px;
    margin-left: -40px;
  }

  
.quiz-container1 ul li {
    @media (min-width: 800px) {
      font-size: 1.3rem;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    /* font-size: 16px; */
    /* font-size: 1.3rem; */
    /* background: #ffffff; */
    background: #8ebaf133;
    /* border: 1px solid #eaeaea; */
    border-radius: 5px;
    padding: 25px 25px;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 350ms ease-in-out;
  }
  
  .quiz-container1 ul li .txt_index {
    border: 2px solid #0571f782;
    padding: 8px 12px;
    line-height: 1;
    text-align: center;
    border-radius: 5px;
    background: white;
  }

  .quiz-container1 ul li:hover {
    border: 1px solid #0089ff;
  }

  .quiz-container1 ul .selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
  }

  .quiz-container1 button {
    background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }

  .quiz-container1 button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
  }

  .quiz-container1 .choice_div {
    @media (min-width: 800px) {
      width: 500px;
      /* width: 100%; */
      max-width: 500px;
      margin: 45px 0 0 0;
    }
    /* width: 500px; */
    /* width: 100%; */
    max-width: 500px;
    margin: 0 0 0 0;
  }
  
body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div_m a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root2 {
    @media (min-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    background-color: #fff;
}
.total_container_m {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div_m {
    flex: 1 0 auto;
}
.foot_img_div_m {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img_m {
    height: 149px;
    width: 100%;
    background-image: url(/static/media/houses2.53bdc365.webp);
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links_m {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container_m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container_m a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div_m {
  padding: 15px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result_m {
    padding: 15px 0px;
}
.content_head_m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result_m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div_m {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div_m:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div_m {
    width: 115px;
}
.content_quiz_div_m {
    /* max-width: 1200px; */
    padding: 0 5px;
    margin: 0 auto;
}
.result_div_font_m {
    font-family: "Roboto";
}
.quiz_div_fadeOut_m {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn_m {
  opacity: 1;
  transition: opacity 1.5s;
}
.content_quiz_container_m {
    margin-top: 30px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 5px;
}
.quiz_question_div_m {
    font-size: 25px!important;
    line-height: 25px!important;
    /* margin-bottom: 50px; */
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container_m {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.quiz_answers_button_div_m {
        display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* border-radius: 10px; */
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    /* min-height: 190px; */
    transition: -webkit-transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s, -webkit-transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
    width: 85%;
    padding: 0 0 0 15px;
    margin-bottom: 15px;
}
.quiz_answers_button_div_m:hover {
    /* transform: translate(0px, -5%); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span_m {
    background-image: url(/static/media/yes.50e2ec53.svg);
    background-size: 70%;
    /* margin: 20px auto 0px; */
    display: block;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span_m {
    min-height: 45px;
    margin: 5px;
    line-height: 22px;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    /* text-align: center; */
    text-align: left;
    display: flex;
    align-items: center;
}
.rvp_talk_advisor_container {
    width: 100%;
    max-width: 760px;
    line-height: 1.4;
    color: #000;
}

.rvp_talk_advisor_flex {
    grid-column-gap: 1.5rem;
    grid-row-gap: 0rem;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, .3fr) minmax(0, 1.55fr);
    grid-auto-columns: 1fr;
    align-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: .75rem;
    display: grid;

    @media screen and (max-width: 800px) {
        grid-column-gap: 10px;
        padding: 0 20px;
    }
}

.rvp_advisor_photo {
    grid-area: span 2 / span 1 / span 2 / span 1;
    width: 100%;
    position: relative;
}

.rvp_ssr_talk_advisor_photo {
    z-index: 5;
    border: 2px solid #fff;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
}

.rvp_ssr_image_cover_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;

    background-color: transparent;
    max-width: 100%;
    display: inline-block;

    vertical-align: middle;
    background: #9ba0a8;

    display: flex;
    justify-content: center;
    align-items: center;
}

.rvp_ssr_talk_advisor_photo_shadow {
    background-color: #0266fd;
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
    -webkit-transform: translate(-7px, -7px);
            transform: translate(-7px, -7px);
}

.rvp_advisor_title {
    align-self: end;
    box-sizing: border-box;
    text-align: start;

    @media screen and (max-width: 800px) {
        order: -9999;
        grid-area: span 1 / span 2 / span 1 / span 2;
    }
}

.rvp_ssr_h1 {
    margin-bottom: 8px;
    font-size: 26px;
    font-weight: 700;
    margin-top: 0;
    line-height: 1.3;
    color: #00162a;
}

.rvp_advisor_info {
    align-self: start;
}

.rvp_ssr_talk_advisor_checklist {
    grid-column-gap: .25rem;
    grid-row-gap: .25rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-left: 10px;
    display: grid;

    box-sizing: border-box;
}

.rvp_ssr_talk_advisor_checkitem {
    background-image: url(https://cdn.prod.website-files.com/5dbc34a79684004f6e7a6c30/6279e57ed5cd4f5cf6049f14_green_check_checklist_mark_icon.svg);
    background-position: 0 4px;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-left: 22px;
    font-size: 16px;
    font-weight: 700;
    text-align: start;

    box-sizing: border-box;
}

.rvp_ssr_talk_advisor_p {
    margin-bottom: 12px;
    line-height: 1.4;
    color: #3d475c;
    font-size: 18px;
    margin-top: 0;
}

.rvp_content_container {
    width: 100%;
    max-width: 760px;
    margin-top: 40px;
    line-height: 1.4;
    color: #000;
}

.rvp_question_container {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
}

.rvp_question_col {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.rvp_col_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.rvp_col_ques_title {
    font-size: 1.5rem;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
        text-align: left;
    }
}

.rvp_col_sub_str {
    font-size: 0.8125rem;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
        text-align: left;
    }
}

.rvp_pl_5_mr_1 {
    /* padding-left: 1.25rem; */
    box-sizing: border-box;
    width: 49%;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.rvp_field_wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;
}

.rvp_flex_col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_col_label_div {
    display: flex;
    width: 100%;
    font-weight: 500;
    margin-bottom: 9px;
}

.rvp_input_field_div {
    position: relative;
    width: 100%;
    margin-bottom: 9px;
}

.rvp_input_field_text {
    border-width: 1px;
    border-color: rgba(75, 85, 99, 0.2);
    /* background: rgb(255, 255, 255); */
    background-color: transparent;
    color: rgb(75, 85, 99);
    font-size: 0.9375rem;
    line-height: 1.2em;
    min-height: 2.375rem;
    touch-action: manipulation;    
    border-radius: .25rem;
    width: 100%;
    padding: .5rem .75rem;
    margin: 0;
    box-sizing: border-box;
}

.rvp_input_field_text_email {
    border-width: 1px;
    border-color: rgba(75, 85, 99, 0.2);
    /* background: rgb(255, 255, 255); */
    background-color: transparent;
    color: rgb(75, 85, 99);
    font-size: 0.9375rem;
    line-height: 1.2em;
    min-height: 2.375rem;
    touch-action: manipulation;    
    border-radius: .25rem;
    width: 100%;
    padding: .5rem .75rem;
    padding-left: 2.5rem;
    margin: 0;
    box-sizing: border-box;
}

.rvp_input_field_text_email::-webkit-input-placeholder {
    color: #c1c4dc;
}

.rvp_input_field_text_email:-ms-input-placeholder {
    color: #c1c4dc;
}

.rvp_input_field_text_email::placeholder {
    color: #c1c4dc;
}

.rvp_absolute_mail_svg {
    position: absolute;
    padding-left: .75rem;
    align-items: center;
    display: flex;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    box-sizing: border-box;
}

.rvp_required_svg {
    width: 1rem;
    height: 1rem;
}

.rvp_btn_div {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 1.25rem;
    border-color: transparent;
    border-width: 2px;
    box-sizing: border-box;
}

.rvp_btn_flex_div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
}

.rvp_btn_btn {
    display: inline-flex;
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-color: transparent;
    border-width: 1px;
    border-radius: .375rem;
    justify-content: center;
    align-items: center;
    text-transform: none;
    overflow: visible;
    margin: 0;
    touch-action: manipulation;
    cursor: pointer;
    box-sizing: border-box;
}

.rvp_btn_btn:hover {
   -webkit-transform: none;
           transform: none;
   background: rgb(88, 149, 247) !important;;
}

.rvp_ant_space {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
}

.rvp_ant_space_item {
    box-sizing: border-box;
}

.rvp_thanks_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}

.rvp_thanks_container {
    /* background: #fff; */
    background-color: transparent;
    max-width: 650px;
    border-radius: .5rem;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    position: relative;
    box-sizing: border-box;
}

.rvp_thanks_check_div_wrapper {
    margin-bottom: .75rem;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.rvp_thanks_check_container {
    width: 48px;
    height: 48px;
    background: #3b82f61a;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;
}

.rvp_thanks_str_div_wrapper {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
}

.rvp_thanks_str_title_div {
    color: #374151;
    box-sizing: border-box;
}

.rvp_thanks_str_title_wrapper {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: center;
}

.rvp_thanks_str_title_p {
    font-size: 1.875rem;
    line-height: 1.3em;
    font-weight: 700;
    margin-bottom: 0px;
    color: #374151;
    margin: 0;
    padding: 0;

}

.rvp_thanks_str_substr_div {
    margin-top: 3px;
    box-sizing: border-box;
    color: #9ba0a8;
    text-align: center;
}

.rvp_thanks_str_substr_p {
    font-size: 0.9375rem;
    line-height: 1.3em;
    font-weight: 400;
    margin-bottom: 0px;
    color: #9ba0a8;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rvp_circle_progress_div_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}


body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root1 {
    background-color: #fff;
    @media (max-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    
}
.total_container {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div {
    flex: 1 0 auto;
}
.foot_img_div {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img {
    height: 149px;
    width: 100%;
    /* background-image: url("assets/houses2.webp"); */
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div {
  padding: 25px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result {
  padding: 15px 0px;
  /* border-bottom: 1px solid rgb(230,230,230); */
}
.content_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div {
    width: 115px;
}
.content_quiz_div {
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.result_div_font {
    font-family: "Roboto";
}
.quiz_div_fadeOut {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn {
  opacity: 1;
  transition: opacity 1.5s;
  
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
.content_quiz_container {
    margin-top: 60px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 15px;
}
.quiz_question_div {
    font-size: 40px!important;
    line-height: 44px!important;
    margin-bottom: 50px;
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container {
    display: flex;
    justify-content: center;
}
.quiz_answers_button_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    min-height: 190px;
    transition: -webkit-transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s, -webkit-transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
}
.quiz_answers_button_div:hover {
    -webkit-transform: translate(0px, -5%);
            transform: translate(0px, -5%);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span {
    background-image: url(/static/media/yes.50e2ec53.svg);
    background-size: 70%;
    margin: 20px auto 0px;
    display: block;
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span {
    /* height: 55px; */
    margin: 5px;
    line-height: 22px;
    /* font-size: 17px; */
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    text-align: center;
    display: block;
}

.quiz-container1 {
    @media (min-width: 800px) {
      width: 800px;
      min-width: 250px;
      background: #ffffff;
      border-radius: 4px;
      margin: 50px auto;
      padding: 100px 60px;
      display: flex;
      flex-direction: column;
      background: transparent;
    }
  
    background: #ffffff;
    border-radius: 4px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  .quiz-container1 h2 {
    /* text-align: center; */
    @media (min-width: 800px) {
      font-size: 1.8rem;
      color: #161a1f;
      line-height: 1.4;
      font-weight: 500;
      margin: 0 0 0 0;
    }
  
    font-size: 1.5rem;
    color: #161a1f;
    line-height: 1.3;
    font-weight: 500;
    margin: 0 0 0 0;
  }
  
  .quiz-container1 h4 {
    @media (min-width: 800px) {
      font-size: 1.6rem;
      color: #161a1f9c;
      line-height: 1.4;
      font-weight: 500;
      margin: 10px 0 0 0;
    }
    font-size: 1.3rem;
    color: #161a1f9c;
    line-height: 1.2;
    font-weight: 500;
    margin: 10px 0 0 0;
  }

  .quiz-container1 ul {
    margin-top: 20px;
    margin-left: -40px;
  }

  
.quiz-container1 ul li {
    @media (min-width: 800px) {
      font-size: 1.3rem;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    /* font-size: 16px; */
    /* font-size: 1.3rem; */
    /* background: #ffffff; */
    background: #8ebaf133;
    /* border: 1px solid #eaeaea; */
    border-radius: 5px;
    padding: 25px 25px;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 350ms ease-in-out;
  }
  
  .quiz-container1 ul li .txt_index {
    border: 2px solid #0571f782;
    padding: 8px 12px;
    line-height: 1;
    text-align: center;
    border-radius: 5px;
    background: white;
  }

  .quiz-container1 ul li:hover {
    border: 1px solid #0089ff;
  }

  .quiz-container1 ul .selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
  }

  .quiz-container1 button {
    background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }

  .quiz-container1 button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
  }

  .quiz-container1 .choice_div {
    @media (min-width: 800px) {
      width: 500px;
      /* width: 100%; */
      max-width: 500px;
      margin: 45px 0 0 0;
    }
    /* width: 500px; */
    /* width: 100%; */
    max-width: 500px;
    margin: 0 0 0 0;
  }

  .save_example_btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
  }
  
body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.learnmore_div_m a:visited {
    color: inherit
}

#root {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background-color: #fff; */
}
#root2 {
    @media (min-width: 800px) {
        display: none;
      }
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    background-color: #fff;
}
.total_container_m {
    height: 100%;
    overflow-x: hidden;
    margin: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(59, 59, 59);
}
.content_div_m {
    flex: 1 0 auto;
}
.foot_img_div_m {
    flex-shrink: 0;
    padding-top: 30px;
}
.house_img_m {
    height: 149px;
    width: 100%;
    background-image: url(/static/media/houses2.53bdc365.webp);
    background-repeat: repeat-x;
    background-position: center bottom;
}
.foot_links_m {
  padding: 5px 0px;
  margin: 0px;
  background-color: rgb(219, 234, 198);
}
.foot_links_container_m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
}
.foot_links_container_m a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
}
.content_head_div_m {
  padding: 15px 0px;
  border-bottom: 1px solid rgb(230,230,230);
}
.content_head_div_result_m {
    padding: 15px 0px;
}
.content_head_m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.content_head_result_m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
}
.left_arrow_div_m {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    border: 1px solid rgb(255, 255, 255);
}
.left_arrow_div_m:hover {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}
.progress_div_m {
    width: 115px;
}
.content_quiz_div_m {
    /* max-width: 1200px; */
    padding: 0 5px;
    margin: 0 auto;
}
.result_div_font_m {
    font-family: "Roboto";
}
.quiz_div_fadeOut_m {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn_m {
  opacity: 1;
  transition: opacity 1.5s;
}
.content_quiz_container_m {
    margin-top: 30px!important;
    text-align: center;
    /* margin: 40px auto 0px; */
    padding: 0px 5px;
}
.quiz_question_div_m {
    font-size: 25px!important;
    line-height: 25px!important;
    /* margin-bottom: 50px; */
    margin: 0px auto 30px;
    max-width: 750px;
    font-family: "Georgia, serif";
    text-align: center;
}
.quiz_answers_container_m {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.quiz_answers_button_div_m {
        display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* border-radius: 10px; */
    cursor: pointer;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    margin-right: 10px;
    width: 220px;
    /* min-height: 190px; */
    transition: -webkit-transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s;
    transition: transform 300ms ease-in-out 0s, -webkit-transform 300ms ease-in-out 0s;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px;
    border: 1px solid rgb(88, 214, 151);
    width: 85%;
    padding: 0 0 0 15px;
    margin-bottom: 15px;
}
.quiz_answers_button_div_m:hover {
    /* transform: translate(0px, -5%); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 5px -8px;
    border-color: rgb(34, 140, 87);
}
.answer_icon_span_m {
    background-image: url(/static/media/yes.50e2ec53.svg);
    background-size: 70%;
    /* margin: 20px auto 0px; */
    display: block;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center center;
}
.answer_text_span_m {
    min-height: 45px;
    margin: 5px;
    line-height: 22px;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: rgb(20, 36, 56);
    cursor: pointer;
    /* text-align: center; */
    text-align: left;
    display: flex;
    align-items: center;
}
.quiz_content_item_div {
    border: 1px solid #c0c2c3a3;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
    transition: all 150ms ease-in-out;
    margin-bottom: 5px!important;
}

.quiz_content_item_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
}

.quiz_content_item_title {
    /* font-size: 16px; */
    font-weight: 600;
}
.sameline_title_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quiz_content_item_div {
    border: 1px solid #c0c2c3a3;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
    transition: all 150ms ease-in-out;
    margin-bottom: 5px!important;
}

.quiz_content_item_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
}

.quiz_content_item_title {
    /* font-size: 16px; */
    font-weight: 600;
}

.quiz_content_item_text {
    overflow-wrap: anywhere;
}

.quiz_child_div {
    padding: 10px 15px!important;
    border: 1px #ebe7e7 solid!important;
    border-radius: 5px!important;
    margin-bottom: 10px;
    transition: all 150ms ease-in-out;
}

.quiz_child_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
 }
