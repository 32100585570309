/* ////////////////////////////////////////// section report ///////////////////////////////////// */

.section_report {
    padding: 30px 0 0;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-family: GT Pressura,sans-serif;
    color: #000000;

    @media (max-width: 800px) {
        padding: 10px 0 0;
    }
}

.container_grid {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
  }

.equal_px_grid {
    padding-left: 20px!important;
    padding-right: 20px!important;

    @media (max-width: 800px) {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
}

.section_report_uptitle {
    font-family: GT Pressura,sans-serif;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0 0 5px;
}

.section_report_downtitle {
    font-family: GT Pressura,sans-serif;
    font-size: 72px;
    line-height: 1.1;
    font-weight: bold;
    margin: 0;
    /* margin: 0 0 30px; */

    @media (max-width: 800px) {
        font-size: 48px;
    }
}

.popular_comparisons {
    padding: 20px 0;
    box-sizing: border-box;
}

.comparison_grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        overflow: auto;
        scrollbar-width: none;
    }
}

.comparison_card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    width: 240px;
    height: 320px;

    @media (max-width: 800px) {
        width: 180px;
    }

}

.comparison_card_a {
    color: #000000;
    text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    outline: none;
}

.comparison_image {
    position: relative;
    aspect-ratio: 1;
    box-sizing: border-box;
    width: 240px;
    height: 320px;

    @media (max-width: 800px) {
        width: 180px;
    }

}

.comparison_image img {
    object-fit: contain;
    width: 100%;
    height: 100%;

    @media (max-width: 800px) {
        object-fit: cover;

    }
}

.comparison_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* bottom: 0; */
    padding: 20px;
    box-sizing: border-box;
}

.comparison_text {
    color: white;
    box-sizing: border-box;
    /* background-color: #0e0e0e78; */

}

.best_label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    box-sizing: border-box;
    font-family: GT Pressura,sans-serif;
}

.comparison_text h3 {
    font-size: 18px;
    font-family: GT Pressura,sans-serif;
    margin: 0;
    font-weight: bold;
    line-height: 1.2;
}

.container_carousel_div {
    box-sizing: border-box;
}

.carousel_wrapper_div {
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

/* ////////////////////////////////////////// section store ///////////////////////////////////// */


.section_store {
    box-sizing: border-box;
}

.store_container {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
}

.store_flex_container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        gap: 25px;
    }
}

.store_header {
    display: flex;
    gap: 25px;
    width: -webkit-fill-available;
    justify-content: space-between;
    box-sizing: border-box;
}

.store_titles_section {
    box-sizing: border-box;
}

.store_titles_section h2 {
    color: #000;
    text-wrap: nowrap;
    font-family: GT Pressura,sans-serif;
    font-size: 50px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -2.5px;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 40px;

    }
}

.store_subtitle {
    color: #716e76;
    font-family: GT Pressura,sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
    letter-spacing: -0.16px;
    margin: 0px;
    font-weight: 500;
    box-sizing: border-box;
}

.store_wrapper_carousel {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.store_wrapper_carousel ul {
    display: flex;
    gap: 20px;
    overflow: hidden;
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        overflow: auto;
        scrollbar-width: none;
    }
}

.store_wrapper_carousel ul li {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 420px;
    height: 480px;
    border-radius: 32px;
    background-color: #f1efe959;
    backdrop-filter: blur(62px);
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 300px;
        height: auto;
        align-items: center;
    }
    
}

.store_wrapper_carousel ul li .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1 / 0.8;
    overflow: hidden;
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .cover img {
    height: 100%;
    width: auto;
    object-fit: contain;
    border-radius: 26px;
    vertical-align: middle;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 270px;
    }
}

.store_wrapper_carousel ul li .info {
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .info p {
    color: #716e76;
    font-family: GT Pressura,sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.18px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .info span {
    box-sizing: border-box;
}

.store_wrapper_carousel ul li .info a {
    color: #000000;
    text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    outline: none;

    box-sizing: border-box;

}
.store_wrapper_carousel ul li .info a.store_children_categories {
    display: flex;
    color: #716e76;
    font-size: 15px;
    position: relative;
    justify-content: center;
    box-sizing: border-box;
}

/* ////////////////////////////////////////// section buyer ///////////////////////////////////// */

.section_buyer {
    padding: 50px 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 10px 0;

    }
}

.buyer_container {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
}

.buyer_reverse {
    box-sizing: border-box;

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.buyer_reverse_text_container {
    box-sizing: border-box;
}

.buyer_reverse_text_container h2 {
    font-family: GT Pressura,sans-serif;
    font-size: 38px;
    line-height: 1.1;
    font-weight: 500;
    margin: 0 0 10px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 31px;
    }
}

.buyer_reverse_text_container p {
    font-family: GT Pressura,sans-serif;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    margin: 0 0 10px;
    color: #716E76;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 17px;
    }
}

.section_buyer img {
    width: 100%;
    height: auto;
    border-radius: 32px;
    vertical-align: middle;
    box-sizing: border-box;
}

.buyer_m_t_50 {
    margin-top: 50px;

    @media (max-width: 800px) {
        margin-top: 20px;
    }
}

.buyer_m_t_50 h2 {
    font-family: GT Pressura,sans-serif;
    font-size: 38px;
    line-height: 1.1;
    font-weight: 500;
    margin: 0 0 10px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 31px;
    }
}

.buyer_m_t_50 p {
    font-family: GT Pressura,sans-serif;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    margin: 0 0 10px;
    color: #716E76;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 17px;
    }
}

/* ////////////////////////////////////////// section subscribe ///////////////////////////////////// */
.section_subscribe {
    padding: 50px 0;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 0px 30px 0px;
    }
}

.subscribe_container {
    padding: 0px 20px;
    max-width: 1320px;
    margin: 0 auto!important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        padding: 0px 10px;
    }
}

.subscribe_container h2 {
    color: #000;
    font-family: GT Pressura,sans-serif;
    font-size: 38px;
    line-height: 1.1;
    font-weight: 500;
    margin: 0 0 32px;
    text-align: center !important;
    box-sizing: border-box;

    @media (max-width: 800px) {
        font-size: 30px;
    }
}
